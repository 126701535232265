import React, { useState, useEffect } from "react";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import FormHelperText from "@material-ui/core/FormHelperText";
import MenuItem from "@material-ui/core/MenuItem";
import Box from "@material-ui/core/Box";
import { makeStyles } from "@material-ui/core/styles";
import { KeyboardDatePicker } from "@material-ui/pickers";
import "date-fns";
import DateFnsUtils from "@date-io/date-fns";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";

var stateObjectT = require("./../countryStateCities");
var stateObject = stateObjectT.countries;

const Joi = require("joi");
const useStyles = makeStyles((theme) => ({
  rootPicture: {
    display: "flex",
    "& > *": {
      margin: theme.spacing(1),
      width: theme.spacing(12),
      height: theme.spacing(12),
    },
  },
  root: {
    width: "100%",
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
  },
  roott: {
    backgroundColor: "#a7cef2",
    border: 5,
    borderRadius: 5,
    color: "black",
    height: 25,
    width: 275,
    padding: "0 30px",
  },
  container: {
    boxShadow: "0 3px 5px 2px rgba(0, 0, 135, .3)",
    padding: "0 30px",
    borderRadius: 10,
  },
  dateContainer: { display: "flex", flexWrap: "wrap" },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 200,
  },
}));

var genders = ["Male", "Female", "Other"];
var countryCodes = ["+91", "+10", "+101", "+44"];
var stateNameD = {};
var imagesHere;

var country = "",
  city = "",
  gender = "",
  state = "",
  countryCode = "",
  mobile = "", stateDisabled = true, cityDisabled = true, zipDisabled = true

const Schema = Joi.object({
  firstName: Joi.string().pattern(/^[A-Za-z]+$/).required(),
  lastName: Joi.string().pattern(/^[A-Za-z]+$/).allow(''),
  email: Joi.string().email({ tlds: { allow: false } }).required(),
  countryCode: Joi.string().trim().required(),
  mobile: Joi.string().length(10).pattern(/^[0-9]+$/).required(),
  city: Joi.string().trim(),
  gender: Joi.string(),
  zip: Joi.string().length(6).pattern(/^[0-9]+$/).allow(''),
  state: Joi.string().trim(),
  country: Joi.string(),
  birthday: Joi.date().allow(null),
  imgBase: Joi.string(),
  occupation: Joi.string().pattern(/^[A-Za-z]+$/).required(),
  qualification: Joi.string().pattern(/^[A-Za-z]+$/).required()
});

var cityNameD = [], countryD, minDate = new Date("1960-01-01"), maxDate = new Date("2003-01-01");

export default function AddressForm(props) {
  const [selectedDate, setSelectedDate] = React.useState(new Date("2000-08-18"));
  const classes = useStyles();
  var [values, setValues] = useState({});
  var [errors, setErrors] = useState({});
  var [image, setImage] = useState(
    "https://cdn1.vectorstock.com/i/thumb-large/71/90/blank-avatar-photo-icon-design-vector-30257190.jpg"
  );

  useEffect(() => {
    setValues(props.data || {});
  }, [props.data]);

  function countrySelected(country) {
    countryD = country;
    stateNameD = stateObject[country];
    stateDisabled = false
  }

  function stateSelected(state) {
    cityNameD = stateNameD[state];
    cityDisabled = false
  }

  const handleChange = (event) => {
    setValues({ ...values, [event.target.id]: event.target.value });
    const { error, value } = Schema.validate({ ...values, [event.target.id]: event.target.value });
    if (error) {
      props.onValid(value, 0, 1);
      setErrors({ [error.details[0].path[0]]: "enter valid information" }); //error.details[0].path[0]
    } else {
      setErrors([])
      typeof props.onValid === "function" ? props.onValid(value, 0) : console.log("handle this");
        var date = value.birthday
        if(maxDate>date &&  date>minDate){
          setErrors({});
          typeof props.onValid === "function" ? props.onValid(value, 0) : console.log("handle this");
        } else if(!date){
          setErrors({});
          typeof props.onValid === "function" ? props.onValid(value, 0) : console.log("handle this");
        } 
        else {
          props.notValid()
        }
    }
  };

  const handleDateChange = (date) => {
    setSelectedDate(date);
    if (date) {
      var ele = {
        target: {
          id: "birthday",
          value: date.toString(),
        },
      };
      handleChange(ele);
    } else if (!date) {
      var elem = {
        target: {
          id: "birthday",
          value: null,
        },
      };
      handleChange(elem);
    } else {
      console.log("both failed");
    }
  }


  return (
    <React.Fragment>
      <div>
        <Typography component="div" variant="subtitle1" style={{ textAlign: "center" }}>
          <Box className={classes.roott} p={1} mx="auto">
            Personal Details
          </Box>
          <br />
        </Typography>
      </div>
      <div className={classes.container}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            <TextField
              required
              error={errors["firstName"] ? true : false}
              id="firstName"
              name="firstName"
              label="First Name"
              fullWidth
              autoComplete="given-name"
              value={values.firstName || ""}
              onChange={(event) => {
                event.target = { ...event.target, ...{ id: "firstName" } };
                handleChange(event);
              }}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              id="lastName"
              name="lastName"
              label="Last Name"
              fullWidth
              autoComplete="family-name"
              value={values.lastName || ""}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12} sm={8}>
            <TextField
              required
              id="email"
              name="email"
              label="Primary Email"
              error={errors["email"] ? true : false}
              fullWidth
              autoComplete="email"
              value={values.email || ""}
              onChange={handleChange}
            />
            <FormHelperText>Enter Your Email </FormHelperText>
          </Grid>
          <Grid item xs={12} sm={4}>
            <FormControl>
              <InputLabel id="gender">Gender</InputLabel>
              <Select
                labelId="gender"
                id="gender"
                label="gender"
                value={values.gender || gender}
                onChange={(event) => {
                  event.target = { ...event.target, ...{ id: "gender" } };
                  handleChange(event);
                }}
              >
                {genders.map((ele, i) => (
                  <MenuItem value={ele} key={i}>
                    {ele}
                  </MenuItem>
                ))}
              </Select>
              <FormHelperText>Select Your Gender</FormHelperText>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={4}>
            <FormControl>
              <InputLabel id="countryCode">Country Code*</InputLabel>
              <Select
                labelId="countryCode"
                id="countryCode"
                name="countryCode"
                label="countryCode"
                value={values.countryCode || countryCode}
                onChange={(event) => {
                  event.target = {
                    ...event.target,
                    ...{ id: "countryCode" },
                  };
                  handleChange(event);
                }}
              >
                {countryCodes.map((ele, i) => (
                  <MenuItem value={ele} key={i}>
                    {ele}
                  </MenuItem>
                ))}
              </Select>
              <FormHelperText>Select Your Country Code</FormHelperText>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={8}>
            <TextField
              required
              id="mobile"
              name="mobile"
              label="Mobile"
              error={errors["mobile"] ? true : false}
              fullWidth
              autoComplete="mobile"
              helperText="Enter Your 10 Digit Mobile number "
              value={values.mobile || mobile}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12} md={6} sm={6}>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              {/* <Grid container justify="space-around"> */}
              <KeyboardDatePicker
                disableFuture={true}
                emptyLabel={'MM/DD/YYYY'}
                fullWidth
                InputLabelProps={{
                  shrink: true,
                }}
                id="birthday"
                label="DOB"
                format="MM/dd/yyyy"
                value={values.birthday || null}
                minDate={"1960-01-01"}
                maxDate={"2010-01-01"}
                autoComplete="date"
                onChange={handleDateChange}
              />
              <FormHelperText>Select Your DOB</FormHelperText>
            </MuiPickersUtilsProvider>
          </Grid>
          <Grid item xs={0} sm={2}></Grid>
          <Grid item xs={12} sm={4}>
            <FormControl>
              <InputLabel id="country">Country</InputLabel>
              <Select
                labelId="country"
                id="country"
                value={values.country || ""}
                onChange={(event) => {
                  event.target = { ...event.target, ...{ id: "country" } };
                  handleChange(event);
                  countrySelected(event.target.value);
                }}
              >
                {Object.keys(stateObject).map((ele, i) => (
                  <MenuItem value={ele} key={i}>
                    {ele}
                  </MenuItem>
                ))}
              </Select>
              <FormHelperText>Select Your Country</FormHelperText>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={4}>
            <FormControl >
              <InputLabel id="state">State</InputLabel>
              <Select
                labelId="state"
                id="state"
                value={values.state || state}
                error={errors["state"] ? true : false}
                onChange={(event) => {
                  event.target = { ...event.target, ...{ id: "state" } };
                  handleChange(event);
                  stateSelected(event.target.value);
                }}
              >
                {Object.keys(stateNameD).map((ele, i) => (
                  <MenuItem value={ele} key={i}>
                    {ele}
                  </MenuItem>
                ))}
              </Select>
              <FormHelperText>Select Your State Name </FormHelperText>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={4}>
            <FormControl >
              <InputLabel id="city">City</InputLabel>
              <Select
                labelId="city"
                id="city"
                value={values.city || city}
                error={errors["city"] ? true : false}
                onChange={(event) => {
                  event.target = { ...event.target, ...{ id: "city" } };
                  handleChange(event);
                }}
              >
                {cityNameD.map((ele, i) => (
                  <MenuItem value={ele} key={i}>
                    {ele}
                  </MenuItem>
                ))}
              </Select>
              <FormHelperText>Select Your City</FormHelperText>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={4}>
            <TextField
              label="Zip / Postal code"
              id="zip"
              name="zip"
              error={errors["zip"] ? true : false}
              fullWidth
              autoComplete="shipping postal-code"
              value={values.zip || ""}
              onChange={handleChange}
            />
            <FormHelperText>Enter Your Postal Code</FormHelperText>
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              label="Occupation"
              id="occupation"
              name="occupation"
              required
              error={errors["occupation"] ? true : false}
              fullWidth
              autoComplete="occupation"
              value={values.occupation || ""}
              onChange={(event) => {
                event.target = { ...event.target, ...{ id: "occupation" } };
                handleChange(event);
              }}
            />
            <FormHelperText>Enter Your Occupation</FormHelperText>
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              label="Qualification"
              id="qualification"
              required
              error={errors["qualification"] ? true : false}
              name="qualification"
              fullWidth
              autoComplete="qualification"
              value={values.qualification || ""}
              onChange={(event) => {
                event.target = { ...event.target, ...{ id: "qualification" } };
                handleChange(event);
              }}
            />
            <FormHelperText>Enter Your Qualification</FormHelperText>
          </Grid>
        </Grid>
        <br />
        <br />
      </div>
      <br />
    </React.Fragment>
  );
}