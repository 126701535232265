import React, { useState, useEffect } from "react";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import FormHelperText from "@material-ui/core/FormHelperText";
import MenuItem from "@material-ui/core/MenuItem";
import Checkbox from "@material-ui/core/Checkbox";
import ListItemText from "@material-ui/core/ListItemText";
import Box from "@material-ui/core/Box";
import { makeStyles } from "@material-ui/core/styles";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";
import { listColleges, listCourses, listSubjects } from "../services/Api";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";

const Joi = require("joi");

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
  },
  roott: {
    background: "linear-gradient(90deg, #A0D0E3 0%, #DBE5E9 100%)",
    border: 5,
    borderRadius: 5,
    color: "black",
    height: 25,
    width: 275,
    padding: "0 30px",
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
  container: {
    boxShadow: "0 3px 5px 2px rgba(0, 0, 135, .3)",
    padding: "0 30px",
    borderRadius: 10,
  },
  noLabel: {
    marginTop: theme.spacing(3),
  },
  chip: {
    margin: 2,
  },
  chips: {
    display: "flex",
    flexWrap: "wrap",
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
    maxWidth: 300,
  },
}));

const Schema = Joi.object({
  college: Joi.object().required(),
  // course: Joi.object().required(),
  course: Joi.array().items(Joi.object().required()),
  values: Joi.object(),
  subjects: Joi.array().items(Joi.object().required()),
  qualification: Joi.string(),
});

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

var response;

var courseArr = []

export default function AcademicsForm(props) {
  const classes = useStyles();
  var [colleges, setColleges] = useState([]);
  var [courses, setCourses] = useState([]);
  var [subjects, setSubjects] = useState([]);

  var [values, setValues] = useState({});
  var [errors, setErrors] = useState({});
  var [loading, setLoading] = useState(false);
  const { executeRecaptcha } = useGoogleReCaptcha();

  const qualifications = [
    "B.Sc Nursing",
    "MBBS",
    "MD",
    "M.Sc Nursing",
    "MS",
    "Ph.D",
    "MCH",
    "DM",
    "DNB",
  ];


  useEffect(() => {
    setValues(props.value[1] || { subjects: [] });
    const getColleges = async (_) => {
      try {
        setLoading(true);
        var token = await executeRecaptcha("list_colleges");
        if (localStorage.instructorCollegeDetails) {
          response = JSON.parse(localStorage.instructorCollegeDetails);
        } else {
          response = await listColleges(token);
          localStorage.setItem(
            "instructorCollegeDetails",
            JSON.stringify(response)
          );
        }
        var { data, status } = response;


        switch (status) {
          case 200:
          case 201:
          case 202: {
            if (data.constructor === [].constructor && data.length)
              data.sort((a, b) =>
                a.displayName > b.displayName
                  ? 1
                  : b.displayName > a.displayName
                    ? -1
                    : 0
              );
            setColleges(data);
            let params = new URL(document.location).searchParams;
            let college_id = params.get("collegeId");
            if (college_id) {
              const selectedCollege = data.find(
                (element) => element._id === college_id
              );
              var value = { ...{ college: selectedCollege }, ...values };
              setValues(value);
            }
            break;
          }
          case 400: {
            console.log("handle 400 error");
            break;
          }
          case 401: {
            console.log("handle 401 error");
            break;
          }
          case 500: {
            console.log("handle 500 error");
            break;
          }
          default: {
            console.log("handle unknow error");
          }
        }
        setLoading(false);
      } catch (error) {
        console.log(error);
      }
    };

    getColleges();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    getCourses();
    // if (props.value[1]) props.onValid(values || {}, 1);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.college]);

  // useEffect(() => {
  //   getSubjects();
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [values.course]);

  const getCourses = async (_) => {
    try {
      setLoading(true);
      if (values.college && values.college._id) {
        var token = await executeRecaptcha("list_courses");
        var response = await listCourses(token, values.college._id);
        var { data, status } = response;
        switch (status) {
          case 200:
          case 201:
          case 202: {
            data.filter((data) => data);
            if (data.constructor === [].constructor && data.length)
              data.sort((a, b) =>
                a.courseName > b.courseName
                  ? 1
                  : b.courseName > a.courseName
                    ? -1
                    : 0
              );
            setCourses(data);
            break;
          }
          case 400: {
            console.log("handle 400 error");
            break;
          }
          case 401: {
            console.log("handle 401 error");
            break;
          }
          case 500: {
            console.log("handle 500 error");
            break;
          }
          default: {
            console.log("handle unknow error");
          }
        }
        setLoading(false);
      }
    } catch (error) {
      console.log({ error });
      throw error;
    }
  };

  const getSubjects = async (id) => {
    try {
      setLoading(true);
      if (values) {
        var token = await executeRecaptcha("list_subjects");
        var response = await listSubjects(token, id);
        var { data, status } = response;
        switch (status) {
          case 200:
          case 201:
          case 202: {
            if (data.constructor === [].constructor && data.length)
              data.sort((a, b) =>
                a.subjectName > b.subjectName
                  ? 1
                  : b.subjectName > a.subjectName
                    ? -1
                    : 0
              );
            for (let i = 0; i < data.length; i++) {
              data[i].course_Id = id
            }
            // setSubjects([...subjects, ...data]);
            // setSubjects(data);
            setLoading(false);
            return data;
            break;
          }
          case 400: {
            console.log("handle 400 error");
            break;
          }
          case 401: {
            console.log("handle 401 error");
            break;
          }
          case 500: {
            console.log("handle 500 error");
            break;
          }
          default: {
            console.log("handle unknow error");
          }
        }
        setLoading(false);
      }
    } catch (error) {
      console.log(error);
    }
  };


  async function SubjectsData(event) {
    setSubjects([])
    var addSubjects = [];
    setValues({ ...values, ...{ course: event.target.value } });
    var temp = event.target.value;
    for (let i = 0; i < temp.length; i++) {
      let arr = await getSubjects(temp[i]._id);
      addSubjects = [...addSubjects, ...arr]
      setSubjects(addSubjects)
    }
    setSubjects(addSubjects)
  }



  const handleChange = (event) => {
    switch (event.target.name) {
      case "college": {
        const selectedCollege = colleges.find(
          (element) => element._id === event.target.value
        );

        // const { error, value } = Schema.validate({ ...{ college: selectedCollege }, ...values })
        const { error, value } = Schema.validate({ college: selectedCollege, values });
        console.log({ error });

        if (error) {
          setErrors({
            [error.details[0].path[0]]:
              error.details[0].message || "enter valid information",
          }); //error.details[0].path[0]
          props.notValid()

        } else {
          setErrors({});
          // props.onValid(value, 1)
          // props.notValid()
        }
        setValues(value);
        // props.notValid();
        console.log(errors);
        break;

      }
      case "course": {
        const selectedCourse = courses.find(
          (element) => element._id === event.target.value[0]
        );


        const { error, value } = Schema.validate({ ...{ course: selectedCourse }, ...values });
        if (error) {
          setErrors({ [error.details[0].path[0]]: error.details[0].message || "enter valid information", }); //error.details[0].path[0]
        } else {
          setErrors({});
          props.onValid(value, 1);
        }
        setValues(value);
        break;
      }
      // 
      case "subjects": {
        const { error, value } = Schema.validate({ ...values, ...{ subjects: event.target.value } });
        console.log({ error });

        if (error) {
          setErrors({
            [error.details[0].path[0]]:
              error.details[0].message || "enter valid information",
          }); //error.details[0].path[0]
          props.notValid()
        } else {
          setErrors({});
          props.onValid(value, 1);
        }
        props.onValid(value, 1);
        setValues(value);
        console.log(errors);
        break;

      }

      case "qualification": {
        var qualifications = event.target.value;
        var obj = {
          college: values.college,
          course: values.course,
          subjects: values.subjects,
          qualification: qualifications,
        };

        const { error, value } = Schema.validate(obj);

        console.log({ error });
        if (error) {
          setErrors({
            [error.details[0].path[0]]:
              error.details[0].message || "enter valid information",
          }); //error.details[0].path[0]
          props.notValid()
        } else {
          setErrors({});
          props.onValid(value, 1);
        }
        // props.onValid(value, 1);
        // props.onValid(value, 1);
        setValues(value);
        console.log(errors);
        break;

      }
      default: {
        console.log("handle default");
      }
    }
  };




  return (
    <React.Fragment>
      <div>
        <Typography component="div" variant="subtitle1">
          <Box className={classes.roott} p={1} mx="auto">
            Educator Academic Details
          </Box>
          <br />
        </Typography>
      </div>

      <div className={classes.container}>
        <Backdrop className={classes.backdrop} open={loading}>
          <CircularProgress color="inherit" />
        </Backdrop>
        <br />
        <br />

        <Grid item xs={12}>
          {/* college */}
          <FormControl>
            <InputLabel id="collegeNameDetails">College*</InputLabel>
            <Select
              labelId="collegeNameDetails"
              name="college"
              value={values.college ? values.college._id : ""}
              defaultValue={values.college ? values.college._id : ""}
              onChange={handleChange}
            >
              {colleges.map((college) => (
                <MenuItem value={college._id} key={college._id}>
                  {college.displayName}
                </MenuItem>
              ))}
            </Select>
            <FormHelperText> Select your college</FormHelperText>
          </FormControl>
        </Grid>
        <br />



        <Grid item xs={12}>
          <FormControl className={classes.formControl}>
            <InputLabel id="subjectNameDetails">Course </InputLabel>
            <Select
              required
              labelId="courseNameDetails"
              id="courseNameDetails"
              multiple
              name="course"
              value={values.course || []}
              onChange={SubjectsData}
              renderValue={(selected) => {
                var names = selected.map((c) => c.courseName);
                names.join(", ");
                return names;
              }}
              MenuProps={MenuProps}
            >

              {courses.map((course) => (
                <MenuItem key={course._id} value={course}>
                  <Checkbox
                    checked={values.course ? values.course.map(function (e) { return e._id; }).indexOf(course._id) > -1 : false} />
                  <ListItemText primary={course.courseName || "N/A"} />
                </MenuItem>
              ))}
            </Select>
            <FormHelperText>
              {props.value[1]
                ? props.value[1].courseNameDetails
                : "Select your course"}
            </FormHelperText>
          </FormControl>
        </Grid>



        <br />

        <Grid item xs={12}>
          <FormControl className={classes.formControl}>
            <InputLabel id="subjectNameDetails">Subjects </InputLabel>
            <Select
              required
              labelId="subjectNameDetails"
              id="subjectNameDetails"
              multiple
              name="subjects"
              value={values.subjects || []}
              onChange={handleChange}
              renderValue={(selected) => {
                var names = selected.map((c) => c.subjectName);
                names.join(", ");
                return names;
              }}
              MenuProps={MenuProps}
            >

              {subjects.map((subject) => (
                <MenuItem key={subject._id} value={subject}>
                  <Checkbox
                    checked={
                      values.subjects
                        ? values.subjects
                          .map(function (e) {
                            return e._id;
                          })
                          .indexOf(subject._id) > -1
                        : false
                    }
                  />
                  <ListItemText primary={subject.subjectName || "N/A"} />

                </MenuItem>
              ))}
            </Select>

            <FormHelperText>
              {props.value[1]
                ? props.value[1].subjectNameDetails
                : "Select your course"}
            </FormHelperText>
          </FormControl>
        </Grid>
        {/* <h6>{props.value[1].subjectNameDetails?props.value[1].subjectNameDetails:""}</h6> */}
        <br />
        <Grid item xs={12}>
          {/* college */}
          <FormControl>
            <InputLabel id="qualification">Qualification</InputLabel>
            <Select
              required
              id="qualification"
              label="qualification"
              name="qualification"
              value={values.qualification || ""}
              // defaultValue={""}
              onChange={handleChange}
            >
              {qualifications.map((ele, i) => (
                <MenuItem value={ele} key={i}>
                  {ele}
                </MenuItem>
              ))}
            </Select>
            <FormHelperText> Select your Highest Qualification</FormHelperText>
          </FormControl>
          <br />
          <br />
        </Grid>
      </div>
      <br />
      <br />
    </React.Fragment>
  );
}