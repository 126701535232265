import React, { useEffect, useReducer } from "react";
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';
// import Avatar from "@material-ui/core/Avatar";



import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';

import Avatar from "@material-ui/core/Avatar";

var image='';
//checkbox
var Checkbox = ({ fnClick, fnChange, title = "", checked = false }) => (
  <label>
    <input
      onClick={e => {
        if (fnClick !== undefined) fnClick(e.target.checked);
      }}
      onChange={e => {
        if (fnChange !== undefined) fnChange(e.target.checked);
      }}
      type="checkbox"
      checked={checked}
    />
    {" By Accepting this form you agree with the storage and handling of your data by this website in accordance with our privacy policy "}
  </label>
);
// close checkbox

const useStyles = makeStyles((theme) => ({

  rootPicture: {
    display: "flex",
    "& > *": {
      margin: theme.spacing(1),
      width: theme.spacing(8),
      height: theme.spacing(8),
    },
  },
  root: {
    width: '100%',
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
  }, 
   backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
  roott: {
    background: 'linear-gradient(90deg, #A0D0E3 0%, #DBE5E9 100%)',
    border: 1,
    borderRadius: 1,
    color: 'black',
    height: 25,
    width: 275,
    padding: '0 30px',
  }, container: {
    boxShadow: '0 3px 5px 2px rgba(0, 0, 135, .3)',
    padding: '0 30px',
    borderRadius: 10
  },
}));

export default function Review(props) {

  var bdayString = "N/A";

  if (props.value[0].birthday) {
    var Dated = new Date(props.value[0].birthday);
    var pmonth = Dated.getMonth();
    var cmonth = pmonth + 1;
    var str = Dated.getDate() + "-" + cmonth + "-" + Dated.getFullYear();
    bdayString = str;
  }
 

  var params = (new URL(document.location)).searchParams;
  var redirect_Id = params.get('redirect');


  const classes = useStyles();

  const initialState = {
    click: false
  };

  const reducer = (state, action) => ({ ...state, ...action });
  const [state, setState] = useReducer(reducer, initialState);

  useEffect(() => {
    if (state.click) {
     props.onValidAcademic(redirect_Id)
    } else if (!state.click) {
      props.notValid() 
    }
  }, [state.click])

  return (

    <React.Fragment>
      <div>
        <Backdrop className={classes.backdrop} open={props.loading} >
            <CircularProgress color="inherit" />
          </Backdrop>
        <Typography component="div" variant="subtitle1" align={"center"} >
          <Box className={classes.roott} p={1} mx="auto">
            Review Details
         </Box>
        </Typography>
      </div>

      <Container >

        <div className={classes.container} >

        <div className={classes.rootPicture} style={{ textAlign: "center" }}>
              <Avatar alt="Display pic" src={props.value[0].imgBase||image} />
            </div>
          <br />
      
          <div className="row">
            <div className="col">
              <Typography align='left' gutterBottom><b>First Name</b></Typography>
            </div>
            <div className="col">
              <Typography> {props.value[0].firstName} {props.value[0].middleName || ""}</Typography>
            </div>
          </div>
          {/* full Name */}


          <div className="row">
            <div className="col">
              <Typography align='left' gutterBottom><b>Last Name</b></Typography>
            </div>
            <div className="col">
              <Typography> {props.value[0].lastName} </Typography>
            </div>
          </div>
          {/*  DOB */}
          <div className="row">
            <div className="col">
              <Typography align='left' gutterBottom><b>Email</b></Typography>
            </div>
            <div className="col">
              <Typography> {props.value[0].email||'N/A'} </Typography>
            </div>
          </div>
          {/* gender */}
          <div className="row">
            <div className="col">
              <Typography align='left' gutterBottom><b>Contact</b></Typography>
            </div>
            <div className="col">
              <Typography> {props.value[0].mobile ||'N/A'} </Typography>
            </div>
          </div>
          <div className="row">
            <div className="col">
              <Typography align='left' gutterBottom><b>Gender</b></Typography>
            </div>
            <div className="col">
              <Typography> {props.value[0].gender ||'N/A'} </Typography>
            </div>
          </div>
          <div className="row">
            <div className="col">
              <Typography align='left' gutterBottom><b>Date Of Birth</b></Typography>
            </div>
            <div className="col">
            <Typography> {bdayString} </Typography>
            </div>
          </div>
          {/* email */}
          <div className="row">
            <div className="col">
              <Typography align='left' gutterBottom><b>Country</b></Typography>
            </div>
            <div className="col">
              <Typography> {props.value[0].country||'N/A'} </Typography>
            </div>
          </div>
          <div className="row">
            <div className="col">
              <Typography align='left' gutterBottom><b>State</b></Typography>
            </div>
            <div className="col">
              <Typography> {props.value[0].state||'N/A'} </Typography>
            </div>
          </div>
          {/* mobile */}
          <div className="row">
            <div className="col">
              <Typography align='left' gutterBottom><b>City</b></Typography>
            </div>
            <div className="col">
              <Typography> {props.value[0].city||'N/A'} </Typography>
            </div>
          </div>
          {/* city */}
          <div className="row">
            <div className="col">
              <Typography align='left' gutterBottom><b>Occupation</b></Typography>
            </div>
            <div className="col">
              <Typography> {props.value[0].occupation||'N/A'} </Typography>
            </div>
          </div>
          {/* state */}
          <div className="row">
            <div className="col">
              <Typography align='left' gutterBottom><b>Qualification</b></Typography>
            </div>
            <div className="col">
              <Typography> {props.value[0].qualification||'N/A'} </Typography>
            </div>
          </div>
          {/* country */}
          {/* <div className="row">
            <div className="col">
              <Typography align='left' gutterBottom><b>Zip</b></Typography>
            </div>
            <div className="col">
              <Typography> {props.value[0].zip||'N/A'} </Typography>
            </div>
          </div> */}

          {/* zip */}

        </div>

      </Container>
      <br />
      <br />

      {/* <Typography component="div" variant="subtitle1" align={"center"} >
        <Box className={classes.roott} p={1} mx="auto">
          Academic Details
         </Box>
      </Typography> */}
      

      <Typography component="div" variant="subtitle1" align={"center"}>
        <Box className={classes.roott} p={1} mx="auto">
          Acknowledgment
         </Box>
      </Typography>

      <br />

      <Grid  >
        <Checkbox
          title="Clicks"
          fnClick={v => setState({ click: v })}
          checked={state.click}
        />

      </Grid>

    </React.Fragment>

  );
}

