import React, { useState, useEffect } from "react";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import FormHelperText from "@material-ui/core/FormHelperText";
import MenuItem from "@material-ui/core/MenuItem";
import TextField from "@material-ui/core/TextField";
import Input from "@material-ui/core/Input";
import Checkbox from "@material-ui/core/Checkbox";
import ListItemText from "@material-ui/core/ListItemText";

import Box from "@material-ui/core/Box";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import { listColleges, listCourses } from "../services/Api";

import { useGoogleReCaptcha } from "react-google-recaptcha-v3";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";

var base64 = require("base-64");

const Joi = require("joi");

var stateObjectT = require("./../countryStateCities");
var stateObject = stateObjectT.countries;

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
    maxWidth: 300,
  },

  noLabel: {
    marginTop: theme.spacing(3),
  },

  root: {
    width: "100%",
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
  root1: {
    background: "linear-gradient(90deg, #A0D0E3 0%, #DBE5E9 100%)",
    border: 5,
    borderRadius: 5,
    color: "black",
    height: 25,
    width: 240,
    padding: "0 30px",
  },
  container: {
    boxShadow: "0 3px 5px 2px rgba(0, 0, 135, .3)",
    padding: "0 30px",
    borderRadius: 10,
  },
}));

var selectedCollege;
var countryCodes = ["+91", "+10", "+101", "+44"],
  countryCode = "",
  mobile = "",
  country = "",
  state = "";


var city = "",
  cityNameD = [],
  stateNameD = {},
  countryD,
  stateDisabled = true,
  cityDisabled = true,
  zipDisabled = true;

const Schema = Joi.object({
  college: Joi.object().required(),
  course: Joi.array().items(Joi.object()),
  email: Joi.string()
    .email({ tlds: { allow: false } })
    .allow(""),
  countryCode: Joi.string().required(),
  mobile: Joi.string()
    .length(10)
    .pattern(/^[0-9]+$/)
    .required(),
  country: Joi.string().required(),
  state: Joi.string().required(),
  city: Joi.string().trim().required(),
  zip: Joi.string()
    .length(6)
    .pattern(/^[0-9]+$/)
    .allow(""),
});

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};


export default function AddressForm(props) {
  const classes = useStyles();
  var [values, setValues] = useState({});
  var [errors, setErrors] = useState({});
  const [loading, setLoading] = React.useState(false);
  var [SelectedCollege, setSelectedCollege] = useState({});
  var [colleges, setColleges] = useState([]);
  var [courses, setCourses] = useState([]);
  var [selCourses, setSelCourses] = useState([]);

  const { executeRecaptcha } = useGoogleReCaptcha();

  function countrySelected(country) {
    countryD = country;
    stateNameD = stateObject[country];
    stateDisabled = false;

  }

  function stateSelected(state) {
    cityNameD = stateObject[countryD][state];
    cityDisabled = false;

  }

  const handleChange = (event) => {
    switch (event.target.name) {
      case "college": {
        selectedCollege = colleges.find(
          (element) => element._id === event.target.value
        );
        setSelectedCollege(selectedCollege);
        var { error, value } = Schema.validate(
          { college: selectedCollege },
          values
        );
        if (error) {
          setErrors({
            [error.details[0].path[0]]:
              error.details[0].message || "enter valid information",
          }); //error.details[0].path[0]
        } else {
          setErrors({});
          props.onValid(value, 0);
        }
        setValues(value);
        props.notValid();
        console.log(errors);
        break;
      }
      case "course": {
        const selectedCourse = event.target.value;
        const { error, value } = Schema.validate({
          ...{ course: selectedCourse },
          ...values,
        });
        if (error) {
          setErrors({
            [error.details[0].path[0]]:
              error.details[0].message || "enter valid information",
          });

        } else {
          setErrors({});
          props.onValid(value, 0);
        }
        setValues(value);
        console.log(errors);
        break;
      }
      default: {
        console.log("handle default");
        const { error, value } = Schema.validate({
          ...values,
          [event.target.id]: event.target.value,
        });
        setValues({ ...values, [event.target.id]: event.target.value });

        if (error) {
          console.log(error);
          props.notValid();
          setErrors({ [error.details[0].path[0]]: "enter valid information" }); //error.details[0].path[0]
        } else {
          setErrors({});
          typeof props.onValid === "function"
            ? props.onValid(value, 0)
            : console.log("handle this");
        }

      }
    }
  };


  useEffect(() => {
    setValues(props.data || {});
    var { error, value } = Schema.validate(props.data);
    if (!error) {
    }
    if (props.data) props.onValid(value || {}, 0);
    getColleges();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    getCourses();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.college]);

  var response;
  const getColleges = async () => {
    try {
      setLoading(true);
      var token = await executeRecaptcha("list_colleges");
      if (localStorage.studentCollegeDetails) {
        var bytes = base64.decode(localStorage.studentCollegeDetails);
        response = JSON.parse(bytes);
      } else {
        response = await listColleges(token);
        var strObj = JSON.stringify(response);
        var encodedData = base64.encode(strObj);
        localStorage.setItem("studentCollegeDetails", encodedData);
      }
      var { data, status } = response;
      switch (status) {
        case 200:
        case 201:
        case 202: {
          if (data.constructor === [].constructor && data.length)
            data.sort((a, b) =>
              a.displayName > b.displayName
                ? 1
                : b.displayName > a.displayName
                ? -1
                : 0
            );
          setColleges(data);
          let params = new URL(document.location).searchParams;
          let college_id = params.get("collegeId");
          if (college_id) {
            const selectedCollege = data.find(
              (element) => element._id === college_id
            );
            var value = { ...{ college: selectedCollege }, ...values };
            setValues(value);
          }
          break;
        }
        case 400: {
          console.log("handle 400 error");
          break;
        }
        case 401: {
          console.log("handle 401 error");
          break;
        }
        case 500: {
          console.log("handle 500 error");
          break;
        }
        default: {
          console.log("handle unknown error");
        }
      }
      setLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  const getCourses = async (_) => {
    try {
      setLoading(true);
      if (values.college && values.college._id) {
        var token = await executeRecaptcha("list_courses");
        var response = await listCourses(token, values.college._id);
        var { data, status } = response;
        switch (status) {
          case 200:
          case 201:
          case 202: {
            if (data.constructor === [].constructor && data.length)
              data.sort((a, b) =>
                a.courseName > b.courseName
                  ? 1
                  : b.courseName > a.courseName
                  ? -1
                  : 0
              );
            setCourses(data);
            break;
          }
          case 400: {
            console.log("handle 400 error");
            break;
          }
          case 401: {
            console.log("handle 401 error");
            break;
          }
          case 500: {
            console.log("handle 500 error");
            break;
          }
          default: {
            console.log("handle unknown error");
          }
        }
        setLoading(false);
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <React.Fragment>
      <div>
        <Typography component="div" variant="subtitle1" style={{paddingBottom:'1%'}}>
          <Box className={classes.root1} p={1} mx="auto" align="center">

            College Details
          </Box>
          {/* <br /> */}
        </Typography>
      </div>

      <Container>
        
        <div className={classes.container}>
          <Backdrop className={classes.backdrop} open={loading}>
            <CircularProgress color="inherit" />
          </Backdrop>
          <br />
          {/* college */}
          {/* <Grid container spacing={3}> */}

          <Grid container spacing={3}>
          <Grid item xs={12}>
            <FormControl fullWidth={true}>
              <InputLabel id="college">College*</InputLabel>
              <Select
                required

                labelId="college"
                name="college"
                value={values.college ? values.college._id : ""}
                // value={collegeID||values.college._id}
                defaultValue={""}
                onChange={handleChange}
              >
                {colleges.map((college) => (
                  <MenuItem value={college._id} key={college._id}>
                    {college.displayName}
                  </MenuItem>
                ))}
              </Select>
              <FormHelperText>
                {"Select your college" || errors["collegeNameDetails"]}
              </FormHelperText>
            </FormControl>
          </Grid>
          {/* course */}
          <Grid item xs={12}>

            <FormControl fullWidth={true}>
              <InputLabel id="course">Course</InputLabel>
              <Select
                labelId="course"
                id="course"
                multiple={true}
                value={values.course || selCourses}
                onChange={(event) => {
                  setValues({ ...values, ...{ course: event.target.value } });
                  setSelCourses(event.target.value);
                }}
                input={<Input />}
                renderValue={(selected) => {
                  var names = selected.map((c) => c.courseName);
                  names.join(", ");
                  return names;
                }}
                MenuProps={MenuProps}
              >
                {courses.map((course) => (
                  <MenuItem key={course._id} value={course}>
                    <Checkbox
                      checked={
                        values.course
                          ? values.course
                              .map(function (e) {
                                return e._id;
                              })
                              .indexOf(course._id) > -1
                          : false
                      }
                    />
                    <ListItemText primary={course.courseName || "N/A"} />
                  </MenuItem>
                ))}

              </Select>
              <FormHelperText>
                {errors["courseNameDetails"] || "Select your course"}
              </FormHelperText>
            </FormControl>

            
          </Grid>


          <Grid item xs={12}>
            <TextField
              id="email"
              name="email"
              label="Contact Email"
              error={errors["email"] ? true : false}
              fullWidth
              autoComplete="email"
              value={values.email || ""}
              onChange={handleChange}
            />

          </Grid>

          <Grid item xs={12} sm={4}>
            <FormControl fullWidth={true}>
              <InputLabel id="countryCode">Country Code*</InputLabel>
              <Select
                required

                labelId="countryCode"
                id="countryCode"
                label="code"
                value={values.countryCode || countryCode}
                onChange={(event) => {
                  event.target = { ...event.target, ...{ id: "countryCode" } };
                  handleChange(event);
                }}
              >
                {countryCodes.map((ele, i) => (
                  <MenuItem value={ele} key={i}>
                    {ele}
                  </MenuItem>
                ))}
              </Select>
              <FormHelperText>Country Code</FormHelperText>
            </FormControl>
          </Grid>

          <Grid item xs={12} sm={8}>
            <TextField
              required
              id="mobile"
              name="mobile"
              label="Contact Phone"
              error={errors["mobile"] ? true : false}
              fullWidth
              autoComplete="mobile"
              helperText="Enter Your 10 Digits Mobile Number "
              value={values.mobile || mobile}
              onChange={handleChange}
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <FormControl>
              <InputLabel id="country">Country*</InputLabel>
              <Select
                required

                labelId="country"
                id="country"
                value={values.country || country}
                onChange={(event) => {
                  event.target = { ...event.target, ...{ id: "country" } };
                  handleChange(event);
                  countrySelected(event.target.value);
                }}
              >
                {Object.keys(stateObject).map((ele, i) => (
                  <MenuItem value={ele} key={i}>
                    {ele}
                  </MenuItem>
                ))}
              </Select>
              <FormHelperText>Select Your Country</FormHelperText>
            </FormControl>
          </Grid>

          <Grid item xs={12} sm={6}>
            <FormControl disabled={stateDisabled}>
              <InputLabel id="state">State*</InputLabel>
              <Select
                required
                labelId="state"
                id="state"
                value={values.state || state}
                onChange={(event) => {
                  event.target = { ...event.target, ...{ id: "state" } };
                  handleChange(event);
                  stateSelected(event.target.value);
                }}
              >
                {Object.keys(stateNameD).map((ele, i) => (
                  <MenuItem value={ele} key={i}>
                    {ele}
                  </MenuItem>
                ))}
              </Select>
              <FormHelperText>Select Your State Name</FormHelperText>
            </FormControl>
          </Grid>


          <Grid item xs={12} sm={6}>
            <FormControl disabled={cityDisabled}>
              <InputLabel id="city">City*</InputLabel>
              <Select
                required

                labelId="city"
                id="city"
                value={values.city || city}
                onChange={(event) => {
                  event.target = { ...event.target, ...{ id: "city" } };
                  handleChange(event);
                  zipDisabled = false;

                }}
              >
                {cityNameD.map((ele, i) => (
                  <MenuItem value={ele} key={i}>
                    {ele}
                  </MenuItem>
                ))}
              </Select>
              <FormHelperText>Select Your City Name</FormHelperText>
            </FormControl>
          </Grid>

          <Grid item xs={12} sm={6}>
            <TextField
              disabled={zipDisabled}

              id="zip"
              name="zip"
              error={errors["zip"] ? true : false}
              label="Zip / Postal code"
              fullWidth
              autoComplete="shipping postal-code"
              value={values.zip || ""}
              onChange={handleChange}
            />
            <FormHelperText>Enter Your Zip Code</FormHelperText>
          </Grid>

          <br />
          </Grid>
        </div>
        <br />
        {/* </Grid> */}
      </Container>
    </React.Fragment>
  );
}

