import React, { useState, useEffect } from "react";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import FormHelperText from "@material-ui/core/FormHelperText";
import MenuItem from "@material-ui/core/MenuItem";
import Box from "@material-ui/core/Box";
import { makeStyles } from "@material-ui/core/styles";
import "date-fns";

import ErrorBoundary from "../ErrorBoundary";
import Avatar from "@material-ui/core/Avatar";

import { KeyboardDatePicker } from "@material-ui/pickers";
import "date-fns";
import DateFnsUtils from "@date-io/date-fns";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";

import { Button } from "@material-ui/core";


const Joi = require("joi");

var stateObjectT = require("./../countryStateCities");
var stateObject = stateObjectT.countries;


const useStyles = makeStyles((theme) => ({

  rootPicture: {
    display: "flex",
    "& > *": {
      margin: theme.spacing(1),
      width: theme.spacing(10),
      height: theme.spacing(10),
    },
  },

  root: {
    width: "100%",
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
  },
  roott: {
    background: "linear-gradient(90deg, #A0D0E3 0%, #DBE5E9 100%)",
    border: 5,
    borderRadius: 5,
    color: "black",
    height: 25,
    width: 200,

    padding: "0 30px",

  },
  container: {
    boxShadow: "0 3px 5px 2px rgba(0, 0, 135, .3)",
    padding: "0 30px",
    borderRadius: 10,
  },
  dateContainer: {
    display: "flex",
    flexWrap: "wrap",
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 200,
  },
}));


var country = "",
  city = "",

  state = "",
  countryCode = "",
  mobile = "",
  cityNameD = [],
  stateNameD = {},

  countryD,
  stateDisabled = true,
  cityDisabled = true,
  zipDisabled = true,
  imagesHere;


var genders = ["Male", "Female", "Other"];
var countryCodes = ["+91", "+10", "+101", "+44"];

const Schema = Joi.object({

  firstName: Joi.string()
    .pattern(/^[A-Za-z]+$/)
    .required(),
  lastName: Joi.string()
    .pattern(/^[A-Za-z]+$/)
    .allow(""),
  email: Joi.string()
    .email({ tlds: { allow: false } })
    .required(),
  countryCode: Joi.string().required(),
  mobile: Joi.string()
    .length(10)
    .pattern(/^[0-9]+$/)
    .required(),
  gender: Joi.string(),
  birthday: Joi.date().allow(null),
  country: Joi.string(),
  state: Joi.string(),
  city: Joi.string().trim(),
  zip: Joi.string()
    .length(6)
    .pattern(/^[0-9]+$/)
    .allow(""),
  imgBase: Joi.string(),
});

export default function AcademicsForm(props) {
  var imgFile;

  const picFunction = async (e) => {
    imgFile = e.target.files[0];
    var tempImgURL = URL.createObjectURL(imgFile);
    setImage(tempImgURL);
    var b64 = await convertBase64(imgFile);
    var eve = {
      target: {
        id: "imgBase",
        value: b64 || values.imgBase,
      },
    };
    handleChange(eve);
  };


  function convertBase64(file) {
    return new Promise((res, rej) => {
      const filereader = new FileReader();
      filereader.readAsDataURL(file);
      filereader.onload = () => {
        res(filereader.result);
      };

      filereader.onerror = (errors) => {
        rej(errors);
      };
    });
  }


  var [values, setValues] = useState({});
  var [errors, setErrors] = useState({});

  var [image, setImage] = useState();

  useEffect(() => {

    setValues(props.value[1] || {});

  }, [props]);

  const classes = useStyles();

  function countrySelected(country) {
    countryD = country;
    stateNameD = stateObject[country];
    stateDisabled = false;
  }

  function stateSelected(state) {
    cityNameD = stateObject[countryD][state];
    cityDisabled = false;
  }

  const handleChange = (event) => {
    const { error, value } = Schema.validate({
      ...values,
      [event.target.id]: event.target.value,
    });

    setValues({ ...values, [event.target.id]: event.target.value });

    if (error) {
      console.log(error);
      props.notValid();
      setErrors({ [error.details[0].path[0]]: "enter valid information" }); //error.details[0].path[0]
    } else {
      setErrors({});
      typeof props.onValid === "function"
        ? props.onValid(value, 1)
        : console.log("handle this");
    }
  };

  const handleDateChange = (date) => {
    if (date) {
      var ele = {
        target: {
          id: "birthday",
          value: date.toString(),
        },
      };
      handleChange(ele);
    } else if (!date) {
      console.log("date here is null");

      var elem = {
        target: {
          id: "birthday",
          value: null,
        },
      };
      handleChange(elem);
    } else {
      console.log("both failed");
    }
  };

  return (
    <ErrorBoundary>
      <React.Fragment>
        <div>
          <Typography component="div" variant="subtitle1">
            <Box className={classes.roott} p={1} mx="auto" align="center">
              Admin Details
            </Box>
            <br />
          </Typography>
        </div>

        <div className={classes.container}>
          <Grid container spacing={3}>
            <Grid item xs={12} style={{ textAlign: "center" }}>
              <Button size="large">
                <label htmlFor="file" id="uploadBtn">
                  <div className={classes.rootPicture}>
                    <Avatar alt="Profile Picture" src={image} />
                  </div>
                  <FormHelperText>Profile Photo</FormHelperText>
                </label>
              </Button>
              <input
                type="file"
                id="file"
                accept='image/*'
                value={imagesHere || ""}
                onChange={(e) => {
                  picFunction(e);
                }}
                style={{ display: "none" }}
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <TextField
                required
                error={errors["firstName"] ? true : false}
                id="firstName"
                name="firstName"
                label="First Name"
                fullWidth
                autoComplete="given-name"
                value={values.firstName || ""}
                onChange={handleChange}
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <TextField
                id="lastName"
                error={errors["lastName"] ? true : false}
                name="lastName"
                label="Last Name"
                fullWidth
                autoComplete="family-name"
                value={values.lastName || ""}
                onChange={handleChange}
              />
            </Grid>

            <Grid item xs={12}>
              <TextField
                required
                id="email"
                name="email"
                label="Primary Email"
                error={errors["email"] ? true : false}
                fullWidth
                autoComplete="email"
                value={values.email || ""}
                onChange={handleChange}
              />
            </Grid>

            <Grid item xs={12} sm={4}>
              <FormControl>
                <InputLabel id="countryCode">Country Code*</InputLabel>
                <Select
                  labelId="countryCode"
                  id="countryCode"
                  label="code"
                  value={values.countryCode || countryCode}
                  onChange={(event) => {
                    event.target = {
                      ...event.target,
                      ...{ id: "countryCode" },
                    };
                    handleChange(event);
                  }}
                >
                  {countryCodes.map((ele, i) => (
                    <MenuItem value={ele} key={i}>
                      {ele}
                    </MenuItem>
                  ))}
                </Select>
                <FormHelperText>Select Country Code</FormHelperText>
              </FormControl>
            </Grid>

            <Grid item xs={12} sm={8}>
              <TextField
                required
                id="mobile"
                name="mobile"
                label="Mobile"
                error={errors["mobile"] ? true : false}
                fullWidth
                autoComplete="mobile"
                helperText="Enter Your 10 Digits Mobile Number "
                value={values.mobile || mobile}
                onChange={handleChange}
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <FormControl>
                <InputLabel id="gender">Gender</InputLabel>
                <Select
                  labelId="gender"
                  id="gender"
                  value={values.gender || ""}
                  onChange={(event) => {
                    event.target = { ...event.target, ...{ id: "gender" } };
                    handleChange(event);
                  }}
                >
                  {genders.map((ele, i) => (
                    <MenuItem value={ele} key={i}>
                      {ele}
                    </MenuItem>
                  ))}
                </Select>
                <FormHelperText>Select Your Gender</FormHelperText>
              </FormControl>
            </Grid>

            <Grid item xs={12} md={6}>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker
                  fullWidth
                  InputLabelProps={{
                    shrink: true,
                  }}
                  disableFuture={true}
                  emptyLabel={"MM/DD/YYYY"}
                  id="birthday"
                  label="DOB"
                  format="MM/dd/yyyy"
                  value={values.birthday || null}
                  minDate={"1940-01-01"}
                  maxDate={"2003-01-01"}
                  autoComplete="date"
                  onChange={handleDateChange}
                />
                <FormHelperText>Select Your DOB</FormHelperText>
              </MuiPickersUtilsProvider>
            </Grid>

            <Grid item xs={12} sm={6}>
              <FormControl>
                <InputLabel id="country">Country</InputLabel>
                <Select
                  labelId="country"
                  id="country"
                  value={values.country || country}
                  onChange={(event) => {
                    event.target = { ...event.target, ...{ id: "country" } };
                    handleChange(event);
                    countrySelected(event.target.value);
                  }}
                >
                  {Object.keys(stateObject).map((ele, i) => (
                    <MenuItem value={ele} key={i}>
                      {ele}
                    </MenuItem>
                  ))}
                </Select>
                <FormHelperText>Select Your Country</FormHelperText>
              </FormControl>
            </Grid>

            <Grid item xs={12} sm={6}>
              <FormControl disabled={stateDisabled}>
                <InputLabel id="state">State</InputLabel>
                <Select
                  labelId="state"
                  id="state"
                  value={values.state || state}
                  onChange={(event) => {
                    event.target = { ...event.target, ...{ id: "state" } };
                    handleChange(event);
                    stateSelected(event.target.value);
                  }}
                >
                  {Object.keys(stateNameD).map((ele, i) => (
                    <MenuItem value={ele} key={i}>
                      {ele}
                    </MenuItem>
                  ))}
                </Select>
                <FormHelperText>Select Your State Name</FormHelperText>
              </FormControl>
            </Grid>

            <Grid item xs={12} sm={6}>
              <FormControl disabled={cityDisabled}>
                <InputLabel id="city">City</InputLabel>
                <Select
                  labelId="city"
                  id="city"
                  value={values.city || city}
                  onChange={(event) => {
                    event.target = { ...event.target, ...{ id: "city" } };
                    handleChange(event);
                    zipDisabled = false;
                  }}
                >
                  {cityNameD.map((ele, i) => (
                    <MenuItem value={ele} key={i}>
                      {ele}
                    </MenuItem>
                  ))}
                </Select>
                <FormHelperText>Select Your City Name</FormHelperText>
              </FormControl>
            </Grid>

            <Grid item xs={12} sm={6}>
              <TextField
                disabled={zipDisabled}
                id="zip"
                name="zip"
                error={errors["zip"] ? true : false}
                label="Zip / Postal code"
                fullWidth
                autoComplete="shipping postal-code"
                value={values.zip || ""}
                onChange={handleChange}
              />
              <FormHelperText>Enter Your Zip Code</FormHelperText>
            </Grid>

            <br />
          </Grid>
          <br />
          <br />
          <br />
        </div>
      </React.Fragment>

    </ErrorBoundary>
  );
}
